import { Footer as CommonFooter, FooterMenu } from "@akte-mord/common";

const MENUS: FooterMenu[] = [
  {
    title: "Cold Case Krimis",
    items: [
      { label: "Die Spur der Bilder", route: "/kriminalfaelle/die-spur-der-bilder" },
      { label: "Das Ende der Jugend", route: "/kriminalfaelle/das-ende-der-jugend" },
      { label: "Lösung", route: "/loesung" },
      { label: "Hilfe & Support", route: "/hilfe" },
      { label: "Händleranfragen", route: "/haendleranfragen" },
    ],
  },
  {
    title: "Social Media",
    items: [
      { label: "Folge uns auf Facebook", route: "https://www.facebook.com/dieaktemord", external: true },
      { label: "Folge uns auf Instagram", route: "https://www.instagram.com/aktemord", external: true },
    ],
  },
  {
    title: "Informationen",
    items: [
      { label: "Versandkosten & Lieferzeiten", route: "/versandkosten-und-lieferzeiten" },
      { label: "Allgemeine Geschäftsbedingungen", route: "/allgemeine-geschaeftsbedingungen" },
      { label: "Widerrufsrecht", route: "/allgemeine-geschaeftsbedingungen#widerrufsrecht" },
      { label: "Datenschutzerklärung", route: "/datenschutzerklaerung" },
      { label: "Impressum", route: "/impressum" },
    ],
  },
];

export const Footer: React.FC = () => <CommonFooter menus={MENUS} />;
